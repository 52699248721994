import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faAngleDoubleRight, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { Card } from "../Card";
import type { MicroCMSAPIContentResponse, Supporter } from "@/lib//microcms.server";

interface SupporterCardProps {
  supporter: MicroCMSAPIContentResponse<Supporter>;
  fixed?: boolean;
  fullHeight?: boolean;
}

export function SupporterCard({ supporter, fixed = true, fullHeight = true }: SupporterCardProps) {
  return (
    <Link
      to={`/supporters/${supporter.id}`}
      aria-label={supporter.name}
      className={clsx(
        "block",
        "outline-none",
        "transition-transform",
        "duration-200",
        "hover:opacity-80",
        "focus:opacity-80",
        "active:scale-95",
        fullHeight ? "h-full" : "h-auto"
      )}
    >
      <Card
        style={{
          scrollSnapAlign: "start",
        }}
        className={clsx("h-full", fixed && ["w-64", "sm:w-auto"])}
      >
        <div className={clsx("-mt-6", "-mx-6", "mb-0", "relative", "pt-[100%]", "w-[calc(100%+(theme(padding.6)*2))]")}>
          <div
            className={clsx("absolute", "inset-0", "bg-cover", "bg-no-repeat", "bg-[top_center]", "overflow-hidden")}
          >
            <picture>
              <source
                type="image/webp"
                srcSet={`
                  ${supporter.avatar.url}?fm=webp&fit=crop&crop=center,center&w=220&h=220 320w,
                  ${supporter.avatar.url}?fm=webp&fit=crop&crop=center,center&w=450&h=450 450w,
                `}
              />
              <img
                sizes="100vw"
                srcSet={`
                  ${supporter.avatar.url}?fit=crop&crop=center,center&w=220&h=220 320w,
                  ${supporter.avatar.url}?fit=crop&crop=center,center&w=450&h=450 450w,
                `}
                src={`${supporter.avatar.url}?fit=crop&crop=center,center&w=450`}
                alt={supporter.name}
              />
            </picture>
          </div>
        </div>
        <div
          className={clsx(
            "-mx-6",
            "-mb-6",
            "py-4",
            "px-6",
            "relative",
            "h-full",
            "flex",
            "flex-col",
            "justify-between"
          )}
        >
          <span className={clsx("block", "text-base", "font-bold", "text-gray-900")}>{supporter.name}</span>
          <div className={clsx("flex", "justify-between", "pt-2")}>
            <div className={clsx("flex", "items-center", "flex-grow")}>
              {supporter.twitterURL && (
                <div className={clsx("text-gray-500", "mr-2", "last:mr-0")}>
                  <FontAwesomeIcon icon={faTwitter} className={clsx("block", "w-4", "h-4", "fill-current")} />
                </div>
              )}
              {supporter.facebookURL && (
                <div className={clsx("text-gray-500", "mr-2", "last:mr-0")}>
                  <FontAwesomeIcon icon={faFacebook} className={clsx("block", "w-4", "h-4", "fill-current")} />
                </div>
              )}
              {supporter.instagramURL && (
                <div className={clsx("text-gray-500", "mr-2", "last:mr-0")}>
                  <FontAwesomeIcon icon={faInstagram} className={clsx("block", "w-4", "h-4", "fill-current")} />
                </div>
              )}
              {supporter.linkURL && (
                <div className={clsx("text-gray-500", "mr-2", "last:mr-0")}>
                  <FontAwesomeIcon icon={faLink} className={clsx("block", "w-4", "h-4", "fill-current")} />
                </div>
              )}
            </div>
            <div className={clsx("flex-shrink-0")}>
              <div className={clsx("text-gray-500", "mr-2", "last:mr-0")}>
                <FontAwesomeIcon icon={faAngleDoubleRight} className={clsx("block", "w-4", "h-4", "fill-current")} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
}
