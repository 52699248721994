import clsx from "clsx";
import type { ReactNode } from "react";

export interface CardProps {
  children: ReactNode;
  className?: string;
  style?: Record<string, any>;
}

export function Card({ className, children, style }: CardProps) {
  return (
    <div
      className={clsx(
        "flex",
        "flex-col",
        "flex-grow",
        "p-6",
        "shadow-elevation-low",
        "rounded-xl",
        "bg-white",
        "overflow-hidden",
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}
